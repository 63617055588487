<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">兑换券</div>
		</div>
		<van-list v-model="loading" @load="getTreBoxEntryListByUserId" :finished="finished" :finished-text="msg"
			:error.sync="error" error-text="请求失败，点击重新加载">
			<template v-for="(item,index) in list">
				<div class="item3 flex-row align-center" v-if="item.targetStatus == 1 || item.targetStatus == 4" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="type">兑换券</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">{{item.proToTreBoxConfigName}}</div>
						<div class="date">截止日期：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around" @click.stop="exchange(index)">
						<div class="text">立 </div>
						<div class="text">即</div>
						<div class="text">兑</div>
						<div class="text">换</div>
					</div>

				</div>

				<div class="item2 flex-row align-center" v-if="item.targetStatus == 2" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 1">实物</div>
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 0">虚拟物品</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">{{item.treBoxRewardInfo.name}}</div>
						<div class="date">兑换时间：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around">
						<div class="text">已</div>
						<div class="text">兑</div>
						<div class="text">换</div>
					</div>

				</div>
			<!-- 	<div class="item2 flex-row align-center" v-if="item.targetStatus == 4" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 1">实物</div>
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 0">虚拟物品</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">{{item.treBoxRewardInfo.name}}</div>
						<div class="date">兑换时间：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around" @click.stop="exchange(index)">
						<div class="text">兑</div>
						<div class="text">换</div>
						<div class="text">异</div>
						<div class="text">常</div>
					</div>
				
				</div> -->
				<div class="item2 flex-row align-center" v-if="item.targetStatus == 5" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 1">实物</div>
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 0">虚拟物品</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">{{item.treBoxRewardInfo.name}}</div>
						<div class="date">兑换时间：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around">
						<div class="text">待</div>
						<div class="text">空</div>
						<div class="text">投</div>
					</div>
				
				</div>
				<div class="item2 flex-row align-center" v-if="item.targetStatus == 6" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 1">实物</div>
						<div class="type" v-if="item.treBoxRewardInfo.isNeedExpress == 0">虚拟物品</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">{{item.treBoxRewardInfo.name}}</div>
						<div class="date">兑换时间：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around">
						<div class="text">已</div>
						<div class="text">空</div>
						<div class="text">投</div>
					</div>
				
				</div>

				<div class="item flex-row align-center" v-if="item.targetStatus == 3" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="type2">兑换券</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">{{item.proToTreBoxConfigName}}</div>
						<div class="date">截止日期：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around">
						<div class="text">已</div>
						<div class="text">过</div>
						<div class="text">期</div>
					</div>
				</div>
			</template>
		</van-list>
		<div class="empty-text" v-if="list.length == 0 && request">没有兑换券</div>

		<div class="model" v-if="dhModal3"></div>
		<div class="dh-modal3 flex-column" v-if="dhModal3">
			<div class="dh-m-header flex-row align-center justify-center">
				<div class="dh-m-header-ti">我的地址</div>
				<div class="dh-m-header-edit" @click.stop="editAddr()">修改地址</div>
			</div>
			<div class="dh-m-center flex-column">
				<div class="dh-addr-info" v-if="userAddress.addressPhone">
					<div class="ah-a-h flex-row align-center">
						<div class="dh-a-h-ti">{{userAddress.addressName}}</div>
						<div class="dh-a-h-ph">{{userAddress.addressPhone}}</div>
					</div>
					<div class="ah-ad-h-c">
						{{userAddress.province}}{{userAddress.city}}{{userAddress.area}}{{userAddress.street}}{{userAddress.address}}
					</div>
				</div>
				<div class="dh-btn-wrap flex-row align-center space-between">
					<div class="dh-btn-w flex-row align-center justify-center" @click.stop="closeDhModal3()">
						<div class="dh-btn-t">取消</div>
					</div>
					<div class="dh-btn-w flex-row align-center justify-center" @click.stop="confirmPay()">
						<div class="dh-btn-t">兑换</div>
					</div>
				</div>
			</div>
			<div class="dh-m-footer flex-row"></div>
		</div>
		<div class="model" v-if="chooseAddressModal" @click.stop="closeChooseAddressModal()"></div>
		<div class="choose-address-modal" v-if="chooseAddressModal">
			<div class="am-title-wrap flex-row justify-center align-center">
				<div class="am-title">选择地区</div>
			</div>
			<div class="ca-con-wrap">
				<div class="flex-row align-center choose-con flex-wrap " v-if="areaArr.provinceIndex>=0">
					<div class="choose-con-item" @click.stop="changeAreaStep(1)">
						{{areaArr.provinceIndex>=0?areaArr.province[areaArr.provinceIndex].name:''}}
					</div>
					<div class="choose-con-item" @click.stop="changeAreaStep(2)"
						:class="areaArr.provinceIndex>=0 &&areaArr.cityIndex ==-1?'choose-con-item-false':''">
						{{areaArr.provinceIndex>=0 &&areaArr.cityIndex ==-1?'市':areaArr.city[areaArr.cityIndex].name}}
					</div>
					<div class="choose-con-item" v-if="areaArr.cityIndex>=0" @click.stop="changeAreaStep(3)"
						:class="areaArr.cityIndex>=0 &&areaArr.areaIndex ==-1?'choose-con-item-false':''">
						{{areaArr.cityIndex>=0 &&areaArr.areaIndex ==-1?'区/县':areaArr.area[areaArr.areaIndex].name}}
					</div>
					<div class="choose-con-item" v-if="areaArr.areaIndex>=0" @click.stop="changeAreaStep(4)"
						:class="areaArr.areaIndex>=0 &&areaArr.streetIndex ==-1?'choose-con-item-false':''">
						{{areaArr.areaIndex>=0 &&areaArr.streetIndex ==-1?'街道/镇':areaArr.street[areaArr.streetIndex].name}}
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==1">
					<div class="addr-con-item flex-row align-center space-between"
						v-for="(item,index) in areaArr.province" :key="index" @click.stop="chooseProvice(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.provinceIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==2">
					<div class="addr-con-item flex-row align-center space-between" v-for="(item,index) in areaArr.city"
						:key="index" @click.stop="chooseCity(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.cityIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==3">
					<div class="addr-con-item flex-row align-center space-between" v-for="(item,index) in areaArr.area"
						:key="index" @click.stop="chooseArea(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.areaIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==4">
					<div class="addr-con-item flex-row align-center space-between"
						v-for="(item,index) in areaArr.street" :key="index" @click.stop="chooseStreet(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.streetIndex" src="../../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="model" v-if="addressModal" @click.stop="closeAddressModal()"></div>
		<div class="address-modal" v-if="addressModal">
			<div class="am-title-wrap flex-row justify-center align-center">
				<div class="am-title">填写地址</div>
				<div class="am-save-btn "
					:class="(!editUserAddress.name || !editUserAddress.address || !editUserAddress.phone || !editUserAddress.desc || !editUserAddress.code)?'am-save-btn-false':''"
					@click.stop="saveAddress()">保存</div>
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">联系人</div>
				<input type="text" v-model="editUserAddress.name" placeholder="联系人" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">手机号码</div>
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="editUserAddress.phone" placeholder="手机号码" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center" @click="editCity()">
				<div class="am-item-ti">地址信息</div>
				<input type="text" v-model="editUserAddress.address" disabled placeholder="地址信息" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">详细地址</div>
				<input type="text" v-model="editUserAddress.desc" placeholder="详细地址" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">邮政编码</div>
				<input type="text" v-model="editUserAddress.code" placeholder="邮政编码" class="am-item-input">
			</div>
		</div>
		<div class="model" v-if="resModal2" @click.stop="closeResModal2()"></div>
		<div class="res-modal2" v-if="resModal2">
			<div class="res-con2" v>
				<div class="res-goods2">抽中{{resData.targetTreBoxRewardName}}</div>
				<img :src="resData.treBoxRewardMianPic" class="res-goods-img" alt="">
			</div>
			<div class="res-btn-wrap flex-row align-center justify-center">
				<div class="res-btn-w flex-row align-center justify-center" @click.stop="closeResModal()">
					<div class="res-btn-t">关闭</div>
				</div>

			</div>
		</div>
		
		<div class="order-btn-wrap flex-column align-center" @click.stop="navOrder()">
			<div class="btn-text">查</div>
			<div class="btn-text">看</div>
			<div class="btn-text">订</div>
			<div class="btn-text">单</div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				loading: false,
				finished: false,
				error: false,
				pageNum: 1,
				pageSize: 10,
				total: '',
				list: [],
				request: 0,
				msg: '',
				addressModal: 0,
				chooseAddressModal: 0,
				resModal2: 0,
				dhModal3: 0,
				userAddress: {

				}, //用户地址
				editUserAddress: {
					name: '',
					phone: '',
					address: '',
					desc: '',
					code: ''
				},
				areaArr: {
					province: [],
					provinceIndex: -1,
					city: [],
					cityIndex: -1,
					area: [],
					areaIndex: -1,
					street: [],
					streetIndex: -1,
					step: 1
				},
				currentIndex: -1,
				resData: {}
			}
		},
		methods: {
			navOrder() {
				this.$router.push('/box/entryorder')
			},
			confirmPay() {
				const that = this
				let params = new FormData()
				console.log(this.list)
				console.log(this.currentIndex)
				params.append('treBoxEntryId', this.list[this.currentIndex].id);
				params.append('userAddressId', this.userAddress.userAddressId)
				Toast.loading({
					message: '兑换中...',
					forbidClick: true,
					duration: 0,
				});
				that.axios.post(config.requestUrl +
					`/front/toTreBox/openTreBoxRewardByUserId`,
					params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						that.dhModal3 = false;
						that.resModal2 = true;
						that.resData = response.data.obj;
						let resd=response.data.obj;
						let targetExpirationTime = resd.updateTime.replace(/-/g, "/");
						resd.formatDate= util.formatDate2(targetExpirationTime);
						that.list[that.currentIndex] = resd
						console.log(that.list)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			closeResModal() {
				this.resModal2 = false;
			},
			selectUserAddress() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl +
					`/front/toTreBox/getUserAddressList`,
					params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj.length > 0) {
							that.userAddress = response.data.obj[0]
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			exchange(index) {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl +
					`/front/toTreBox/getUserAddressList`,
					params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.currentIndex = index;
						if (response.data.obj.length > 0) {
							that.userAddress = response.data.obj[0]
							that.dhModal3 = true;
						} else {
							that.addressModal = true;
						}
						console.log(response)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navMember() {
				this.$router.replace('/collect')
			},
			getTreBoxEntryListByUserId() {
				const that = this
				let params = new FormData()
				params.append('pageNum', that.pageNum)
				params.append('pageSize', that.pageSize)
				// params.append('configId',1)
				that.axios.post(config.requestUrl +
					`/front/toTreBox/getTreBoxEntryListByUserId`,
					params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.request = 1;
						that.pageNum = that.pageNum + 1;
						let list = that.list;
						let newList = response.data.obj.list;
						for (let i in newList) {
							if (newList[i].targetStatus == 2) {
								let targetExpirationTime = newList[i].updateTime.replace(/-/g, "/");
								newList[i].formatDate = util.formatDate2(targetExpirationTime)
							} else {
								let targetExpirationTime = newList[i].targetExpirationTime.replace(/-/g, "/");
								newList[i].formatDate = util.formatDate2(targetExpirationTime)
							}
						}
						if (newList.length < that.pageSize) that.finished = true;
						list = list.concat(newList);
						that.list = list;
						that.total = response.data.total
						if (that.request == 1 && that.list.length > 0) {
							that.msg = '没有更多了'
						}
					} else {
						that.error = true
						Toast(response.data.msg);
					}
					that.loading = false;
				}, response => {
					that.error = true
					that.loading = false;
					Toast('获取失败，请稍后重试');
				})
			},
			saveAddress() {
				const that = this;
				console.log(that.areaArr)
				if (!that.editUserAddress.name) {
					Toast('请输入姓名');
					return;
				}
				if (!that.editUserAddress.phone) {
					Toast('请输入手机号码');
					return;
				}
				if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.editUserAddress.phone))) {
					Toast('请输入正确的手机号')
					return;
				}
				if (!that.editUserAddress.address) {
					Toast('请输入地址信息');
					return;
				}
				if (!that.editUserAddress.desc) {
					Toast('请输入详细地址');
					return;
				}
				if (!that.editUserAddress.code) {
					Toast('请输入邮政编码');
					return;
				}

				let params = new FormData()
				params.append('provinceId', that.areaArr.province[that.areaArr.provinceIndex].id);
				params.append('cityId', that.areaArr.city[that.areaArr.cityIndex].id);
				params.append('areaId', that.areaArr.area[that.areaArr.areaIndex].id);
				params.append('townId', that.areaArr.street[that.areaArr.streetIndex].id);
				params.append('address', that.editUserAddress.desc);
				params.append('linkName', that.editUserAddress.name);
				params.append('linkPhone', that.editUserAddress.phone);
				params.append('isDefault', 1)
				let url = '/front/toTreBox/addUserAddress'
				if (that.userAddress.addressPhone) {
					params.append('userAddressId', that.editUserAddress.userAddressId);
					url = '/front/toTreBox/updateUserAddress'
				}
				that.axios.post(config.requestUrl +
					url,
					params, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						Toast('保存成功')
						that.selectUserAddress();
						that.addressModal = 0;
						that.buy();
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			closeDhModal3() {
				this.dhModal3 = 0;
			},
			changeAreaStep(step) {
				if (step != this.areaArr) {
					if (step == 2) {
						if (this.areaArr.provinceIndex >= 0) {
							this.areaArr.step = 2;
						}
					} else if (step == 3) {
						if (this.areaArr.cityIndex >= 0) {
							this.areaArr.step = 3;
						}
					} else if (step == 4) {
						if (this.areaArr.areaIndex >= 0) {
							this.areaArr.step = 4;
						}
					} else {
						this.areaArr.step = 1;
					}
				}
			},
			chooseProvice(index) {
				const that = this;
				this.areaList(this.areaArr.province[index].id, 2, (() => {
					if (that.areaArr.provinceIndex != index) {
						that.areaArr.cityIndex = -1;
						that.areaArr.areaIndex = -1;
						that.areaArr.streetIndex = -1
					}
					that.areaArr.provinceIndex = index;
					that.areaArr.step = 2;
				}))
			},
			chooseCity(index) {
				const that = this;
				this.areaList(this.areaArr.city[index].id, 3, (() => {
					if (that.areaArr.cityIndex != index) {
						that.areaArr.areaIndex = -1;
						that.areaArr.streetIndex = -1
					}
					that.areaArr.cityIndex = index;
					that.areaArr.step = 3;
				}))
			},
			chooseArea(index) {
				const that = this;
				this.areaList(this.areaArr.area[index].id, 4, (() => {
					if (that.areaArr.areaIndex != index) {
						that.areaArr.streetIndex = -1
					}
					that.areaArr.areaIndex = index;
					that.areaArr.step = 4;
				}))
			},
			chooseStreet(index) {
				const that = this;
				that.areaArr.streetIndex = index;
				this.chooseAddressModal = 0
				this.addressModal = 1;
				this.editUserAddress.address = '' + that.areaArr.province[that.areaArr.provinceIndex].name +
					that.areaArr.city[that.areaArr.cityIndex].name + that.areaArr.area[that.areaArr.areaIndex].name +
					that.areaArr.street[that.areaArr.streetIndex].name

			},
			closeAddressModal() {
				this.addressModal = 0
			},
			closeChooseAddressModal() {
				this.chooseAddressModal = 0
			},
			editCity() {
				this.addressModal = 0
				this.chooseAddressModal = 1;
				this.step = 1;
				this.areaList(100000, 1)
			},
			areaList(parentId, level, cb) {
				const that = this;
				var url = ''
				let params = new FormData()
				switch (level) {
					case 1:
						url = '/front/toTreBox/getProvinceList';
						break;
					case 2:
						url = '/front/toTreBox/getCityList';
						params.append('parentId', parentId)
						break;
					case 3:
						url = '/front/toTreBox/getAreaList';
						params.append('parentId', parentId)
						break;
					case 4:
						url = '/front/toTreBox/getTownList';
						params.append('parentId', parentId)
						break;
				}
				that.axios.post(config.requestUrl + url,
					params, {
						headers: {
							'Content-Type': 'text/xml',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						switch (level) {
							case 1:
								that.areaArr.province = response.data.obj;
								break;
							case 2:
								that.areaArr.city = response.data.obj;
								break;
							case 3:
								that.areaArr.area = response.data.obj;
								break;
							case 4:
								that.areaArr.street = response.data.obj;
								break;
							default:
								break;
						}
						if (cb) cb();
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			editAddr() {
				const that = this;
				if (this.userAddress.addressPhone && !this.editUserAddress.phone) {
					console.log(0)
					this.editUserAddress = {
						name: this.userAddress.addressName,
						phone: this.userAddress.addressPhone,
						address: this.userAddress.province + this.userAddress.city + this.userAddress.area + this
							.userAddress.street,
						desc: this.userAddress.address,
						code: this.userAddress.zip,
						userAddressId: this.userAddress.userAddressId
					}
					this.areaList(100000, 1, (() => {
						for (let i in that.areaArr.province) {
							if (that.areaArr.province[i].id == that.userAddress.provinceId) {
								that.areaArr.provinceIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.provinceId, 2, (() => {
						for (let i in that.areaArr.city) {
							if (that.areaArr.city[i].id == that.userAddress.cityId) {
								that.areaArr.cityIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.cityId, 3, (() => {
						for (let i in that.areaArr.area) {
							if (that.areaArr.area[i].id == that.userAddress.areaId) {
								that.areaArr.areaIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.areaId, 4, (() => {
						for (let i in that.areaArr.street) {
							if (that.areaArr.street[i].id == that.userAddress.streetId) {
								that.areaArr.streetIndex = i;
								continue;
							}
						}
					}))
					this.dhModal3 = 0;
					this.addressModal = 1;
				} else {
					this.dhModal3 = 0;
					this.addressModal = 1;
				}

			},
		}
	}
</script>

<style scoped="scoped">
	.item {
		width: 690px;
		height: 165px;
		margin: 20px auto 0;
		background: #FFFFFF;
		background-image: url(../../assets/points/card-status1.png);
		background-size: 690px 165px;
	}

	.price-wrap {
		width: 260px;
	}

	.item .price {
		font-size: 80px;
		color: #808080;
	}

	.type {
		font-size: 50px;
		color: #000000;
	}

	.type2 {
		font-size: 50px;
		color: #808080;
	}

	.item .unit {
		font-size: 26px;
		font-weight: 400;
		margin-left: 5px;
		color: #808080;
		line-height: 55px;
	}

	.item .c-wrap {
		margin-left: 30px;
		width: 340px;
	}

	.item .limit {
		font-size: 28px;
		font-weight: 400;
		color: #808080;
	}

	.item .date {
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
		margin-top: 17px;
	}

	.status {
		height: 125px;
	}

	.item .text {
		font-size: 26px;
		font-weight: 600;
		color: #808080;
	}

	.item2 {
		width: 690px;
		height: 165px;
		margin: 20px auto 0;
		background: #FFFFFF;
		background-image: url(../../assets/points/card-status3.png);
		background-size: 690px 165px;
	}


	.item2 .price {
		font-size: 80px;
		color: #6236FF;

	}

	.item2 .unit {
		font-size: 26px;
		font-weight: 400;
		margin-left: 5px;
		color: #000000;
		line-height: 55px;
	}

	.item2 .c-wrap {
		margin-left: 30px;
		width: 340px;
	}

	.item2 .limit {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.item2 .date {
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
		margin-top: 17px;
	}


	.item2 .text {
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.item3 {
		width: 690px;
		height: 165px;
		margin: 20px auto 0;
		background: #FFFFFF;
		background-image: url(../../assets/points/card-status2.png);
		background-size: 690px 165px;
	}


	.item3 .price {
		font-size: 80px;
		color: #E02020;

	}

	.item3 .unit {
		font-size: 26px;
		font-weight: 400;
		margin-left: 5px;
		color: #000000;
		line-height: 55px;
	}

	.item3 .c-wrap {
		margin-left: 30px;
		width: 340px;
	}

	.item3 .limit {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.item3 .date {
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
		margin-top: 17px;
	}


	.item3 .text {
		font-size: 26px;
		font-weight: 600;
		color: #79401A;
	}

	.price2 {
		font-size: 47px !important;
	}

	.unit2 {
		line-height: 40px !important;
	}

	.empty-text {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		text-align: center;
		margin-top: 621px;
	}





	.box-num {
		width: 349px;
		height: 60px;
		margin: 37px auto 27px;
		background: #FFFFFF;
		border-radius: 40px;
		opacity: 0.39;
		text-align: center;
		line-height: 60px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.box-name {
		width: 590px;
		height: 130px;
		margin: auto;
		background: url(../../assets/points/name-bg.png);
		background-size: 590px 130px;
		line-height: 130px;
		text-align: center;
		font-size: 40px;
		font-weight: 600;
		color: #000000;
		letter-spacing: 10px;
	}

	.box-item-wrap {
		width: 586px;
		height: 586px;
		border: 5px solid #ffffff;
		margin: 34px auto 0;
		background: #ffffff;
		box-sizing: border-box;
		border-radius: 15px;
	}

	.box-item {
		width: 192px;
		height: 192px;
		background: #BFA88D;
		border: 1px solid #ffffff;
		box-sizing: border-box;
	}

	.box-item-con {
		width: 146px;
		height: 146px;
		text-align: center;
		line-height: 146px;
		background: url(../../assets/points/icon-num-bg.png);
		background-size: 146px 146px;
	}

	.icon-num-bg {
		width: 146px;
		height: 146px;
	}

	.box-desc {
		width: 590px;
		height: 173px;
		padding: 40px 20px 20px;
		box-sizing: border-box;
		margin: auto;
		background: url(../../assets/points/desc-bg.png);
		background-size: 590px 173px;
		font-size: 28px;
		font-weight: 600;
		color: #6C4C27;
	}

	.desc-wrap {
		width: 690px;
		min-height: 80px;
		padding-top: 60px;
		background: #FFFFFF;
		border-radius: 20px;
		border: 2px solid #AF9270;
		margin: 121px auto 20px;
		position: relative;
	}

	.desc-ti {
		position: absolute;
		z-index: 10;
		top: -43px;
		left: 141px;
		width: 409px;
		height: 87px;
		background: url(../../assets/points/name-bg.png);
		background-size: 409px 87px;
		font-size: 28px;
		font-weight: 600;
		color: #4E3517;
		line-height: 87px;
		letter-spacing: 4px;
		text-align: center;
	}

	.ch-wrap {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 100;
		width: 750px;
		height: 98px;
		background: #FFFFFF;
		box-shadow: -1px 0px 0px 0px #FFFFFF;
		border-radius: 20px 20px 0 0;
		overflow: hidden;
	}

	.ch-left {
		width: 375px;
		height: 98px;
		font-size: 30px;
		font-weight: 600;
		line-height: 98px;
		text-align: center;
		color: #FFFFFF;
		background: #AF9270;
	}

	.ch-right {

		width: 373px;
		height: 98px;
		background: #AF9270;
	}

	.ch-btn-wrap {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #FFFFFF;
		background: #AF9270;

	}

	.ch-btn {
		width: 190px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #AF9270;
		text-align: center;
		line-height: 59px;
	}

	.dh-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 610px;
		height: 839px;
		background-image: url(../../assets/points/modal-bg.png);
		background-size: 610px 839px;
	}

	.dh-tips {
		margin-top: 94px;
		width: 610px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}

	.dh-points {
		width: 296px;
		height: 77px;
		margin: 19px auto 0;
		background: #F3EEE5;
		border-radius: 39px;
		text-align: center;
		line-height: 77px;
		font-size: 30px;
		font-weight: 600;
		color: #937757;
	}

	.dh-line1 {
		width: 530px;
		height: 1px;
		background: rgba(0, 0, 0, 0.1000);
		margin: 70px auto 51px;
	}

	.dh-tips2 {
		width: 610px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}

	.dh-user-points {
		width: 296px;
		height: 77px;
		margin: 19px auto 0;
		background: rgba(98, 54, 255, 0.0600);
		border-radius: 39px;
		text-align: center;
		line-height: 77px;
		font-size: 30px;
		font-weight: 600;
		color: #6236FF;
	}

	.dh-line2 {
		width: 595px;
		height: 2px;
		background: #B29574;
		margin: 60px auto 30px;
	}

	.dh-xy {
		width: max-content;
		padding: 11px 35px;
		margin: auto;
		background: linear-gradient(90deg, #FFFFFF 0%, #FDE9CC 50%, #FFFFFF 100%);
		border-radius: 25px;
		font-size: 20px;
		font-weight: 400;
		color: #FA6400;
	}

	.dh-btn-wrap {
		width: 450px;
		margin: 56px auto 0
	}

	.dh-btn-w {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.dh-btn-t {
		width: 190px;
		height: 56px;
		text-align: center;
		line-height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.res-modal {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		z-index: 1001;
		margin: auto;
		width: 548px;
		height: max-content;
		max-height: 100%;
		overflow: scroll;
	}

	.res-con {
		width: 548px;
		height: 1010px;
		background-image: url(../../assets/points/res-bg.png);
		background-size: 548px 1010px;
		overflow: hidden;
	}

	.res-goods {
		margin-top: 481px;
		margin-bottom: 28px;
		width: 548px;
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		color: #000000;
	}

	.res-tips {
		width: 548px;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
	}

	.res-tips2 {
		width: 452px;
		height: 50px;
		margin: 27px auto 0;
		background: linear-gradient(90deg, #FFFFFF 0%, #FDE9CC 50%, #FFFFFF 100%);
		border-radius: 25px;
		text-align: center;
		line-height: 50px;
	}

	.res-btn-wrap {
		width: 450px;
		margin: 52px auto;
	}

	.res-btn-w {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.res-btn-t {
		width: 190px;
		height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 56px;
	}

	/* .c-wrap {
		width: 650px;
		padding: 0 20px;
	}
	
	.c-wrap>>>img {
		max-width: 100% !important;
		height: auto !important;
	} */

	.address-modal {
		width: 750px;
		height: 776px;
		background: #ffffff;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1001;
	}

	.am-title-wrap {
		width: 640px;
		height: 88px;
		margin: auto;
		border-bottom: 1px solid #BFA88D;
		position: relative;
	}

	.am-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.am-save-btn {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10px;
		margin: auto;
		width: 100px;
		height: 50px;
		font-size: 26px;
		font-weight: 500;
		color: #FFFFFF;
		text-align: center;
		line-height: 50px;
		background: #BEA78C;
		color: #ffffff;
		border-radius: 8px;
	}

	.am-save-btn-false {
		background: rgba(0, 0, 0, 0.25);
	}

	.am-item {
		width: 650px;
		height: 110px;
		margin: auto;
		box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.am-item-ti {
		width: 177px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.am-item-input {
		width: 470px;
		font-size: 28px;
		border: none;
		background: initial;
	}


	.res-modal2 {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		z-index: 1001;
		margin: auto;
		width: 548px;
		height: max-content;
		max-height: 100%;
		overflow: scroll;
	}

	.res-con2 {
		width: 548px;
		height: 1010px;
		background-image: url(../../assets/points/res-bg2.png);
		background-size: 548px 1010px;
		overflow: hidden;
	}

	.res-goods2 {
		margin-top: 481px;
		margin-bottom: 28px;
		width: 548px;
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		color: #000000;
	}

	.res-goods-img {
		width: 286px;
		height: 315px;
		display: block;
		margin: 34px auto 0;
	}

	.choose-address-modal {
		width: 750px;
		height: 776px;
		background: #ffffff;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1001;
	}

	.ca-con-wrap {
		width: 640px;
		margin: auto;
	}

	.choose-con {
		height: 100px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.choose-con-item {
		/* height: 100px; */
		margin-right: 40px;
		/* line-height: 100px; */
		font-size: 28px;
		font-weight: 600;
		color: #000000;

	}

	.choose-con-item:last-child {
		margin-right: 0;
	}

	.choose-con-item-false {
		color: rgba(0, 0, 0, 0.25);
	}

	.addr-con {
		height: 590px;
		overflow: scroll;
	}

	.addr-con-item {
		margin: auto;
		width: 640px;
		height: 100px;
		box-sizing: border-box;
		padding: 0 10px;
		background: #FFFFFF;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.addr-con2 {
		height: 690px;
	}

	.dh-modal3 {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 610px;
		height: max-content;
		overflow: scroll;
		max-height: 90%;
	}

	.dh-m-center {
		width: 610px;
		height: max-content;
		background-image: url(../../assets/points/modal-bg2.png);
		background-size: 610px 32px;
		background-repeat: repeat-y;

	}

	.dh-m-footer {
		width: 610px;
		height: 90px;
		background-image: url(../../assets/points/modal-bg.png);
		background-size: 610px 839px;
		background-position: 0 90px;

	}

	.dh-m-header {
		width: 611px;
		height: 122px;
		background-image: url(../../assets/points/modal-bg.png);
		background-size: 610px 839px;
		/* background-position: 0 90px; */

	}

	.dh-m-header-ti {
		font-size: 26px;
		font-weight: 600;
		color: #B29574;
	}

	.dh-m-header-edit {
		padding: 6px 11px;
		background: #F3EEE5;
		border-radius: 18px;
		font-size: 16px;
		font-weight: 400;
		color: #866B4B;
		position: absolute;
		top: 40px;
		right: 57px;
	}

	.dh-addr-info {
		width: 408px;
		padding: 0 0 46px 0;
		margin: auto;
	}

	.dh-a-h-ti {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		margin-right: 20px;
	}

	.dh-a-h-ph {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.ah-ad-h-c {
		margin-top: 20px;
	}

	.mt0 {
		margin-top: 0;
	}

	.am-item-input-false {
		color: #cccccc;
	}

	.icon-choose {
		width: 38px;
		height: 38px;
	}
	
	.order-btn-wrap {
		position: fixed;
		right: 0;
		/* top: 0; */
		/* bottom: 0; */
		bottom: 250px;
		margin: auto;
		z-index: 10;
		width: 50px;
		height: max-content;
		padding: 11px 0;
		box-sizing: border-box;
		border-radius: 10px 0px 0px 10px;
		background: #000000;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
	}
</style>